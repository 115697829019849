<template>
  <div>
    <WsCreate
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
    >
    </WsCreate>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/ws_blog_class";
export default {
  metaInfo() {
    return {
      title: `${this.$t("新增")} ${this.$t(this.label)}`,
    };
  },
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name"],
            },
          ],
        },
      ],
      secondary: [],
    },
  }),
};
</script>

<style>
</style>